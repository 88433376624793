import React from 'react';
import logo from './image/default.png';
import {
  PageContainer,
  InnerContainer,
  TextSideContainer,
  ImageSideContaioner,
} from './style';
function App() {
  return (
    <PageContainer>
      <InnerContainer>
        <TextSideContainer>
          <h2>
            Blocks Ventures is an investment firm committed to exceptional
            returns for investors through actively managed portfolios of digital
            blockchain assets.
          </h2>
          <h3>
            Contact:
            <a href="mailto: info@blocks.ventures">info@blocks.ventures</a>
          </h3>
        </TextSideContainer>
        <ImageSideContaioner>
          <img src={logo} alt="blocks-venture" />
        </ImageSideContaioner>
      </InnerContainer>
    </PageContainer>
  );
}
export default App;
