import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
html{
    box-sizing: border-box;
    font-size: 16px;

}
*, *:before, *:after {
    box-sizing: inherit;
  }
  body{
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-family: 'Roboto Mono', monospace;
  }
`;

export const PageContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerContainer = styled.div`
  display: flex;
  gap: 100px;
  @media only screen and (max-width: 769px) {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
  }
`;

export const TextSideContainer = styled.div`
  width: 600px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  h2,
  h3 {
    margin: 0px;
  }
  h2 {
    font-size: 2.3rem;
  }
  h3 {
    font-size: 1.5rem;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        color: blue;
        text-decoration: underline;
      }
    }
  }
  @media only screen and (max-width: 769px) {
    width: 100%;
    height: 300px;
    padding: 0px 20px;
    gap: 20px;
    h2 {
      font-size: 1.3rem;
      width: 100%;
    }
    h3 {
      font-size: 1rem;
    }
  }
`;

export const ImageSideContaioner = styled.div`
  width: 600px;
  height: 600px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  @media only screen and (max-width: 769px) {
    width: 320px;
    height: 320px;
  }
`;
